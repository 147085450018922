import * as React from 'react';
import Image from 'gatsby-image';
import { Typography, useMediaQuery } from '@material-ui/core';
import {
  makeStyles, ThemeProvider
} from '@material-ui/styles';
import { grey } from '@material-ui/core/colors'
import { getFixedGatsbyImage } from 'gatsby-source-sanity';

import sanityConfig from '../../utils/sanity/client-config';
import theme from '../../themes/lightTheme';
import Marquee from "react-fast-marquee";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '16px',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },
  imgContainer: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  },
  imgWrapper: {
    background: 'white',
    padding: 16,
    boxShadow: '16px 32px 74px 0px rgba(126, 138, 253, 0.08)',
    borderRadius: '12px',
    height: 92,
    width: 158,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    // width: 100,
    padding: theme.spacing()
  },
  imgSm: {
    // width: 65,
    padding: theme.spacing()
  },
  title: {
    fontStyle: 'italic',
    // color: grey[500],
    marginBottom: theme.spacing()
  }
}));



const CredBanner = ({ data, offset=false }) => {
  const classes = useStyles();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <Typography className={classes.title} align='center' variant='h5'>{data?.title}</Typography>
      <div className={classes.imgContainer} style={{marginLeft: offset ? 220 : 0, overflow: 'visible'}}>
        <Marquee style={{overflow: 'visible'}} loop={0} speed={offset ? 5 : 10}>
          {data?.logos?.map(l => <div className={classes.imgWrapper} style={{ marginLeft: screenIsSmall ? 45 : 90 }}>
            <div className={screenIsSmall ? classes.imgSm : classes.img}>
              <Image imgStyle={{ margin: 0 }} fixed={getFixedGatsbyImage(
                l?.asset?._id,
                { width: screenIsSmall ? 65 : 80 },
                sanityConfig
              )} />
            </div>
          </div>)}
        </Marquee>
      </div>
    </div>
  </ThemeProvider>
}

export default CredBanner;
