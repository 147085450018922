import * as React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Image from 'gatsby-image';
import {
  Dialog, IconButton
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {
  PlayArrowRounded as Play,
  Close
} from '@material-ui/icons';
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    background: 'transparent'
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  videoContainer: {
    width: 400,
    maxWidth: '100vw',
    maxHeight: 250,
    overflow: 'hidden'
  },
  video: {
    marginBottom: 0
  }
}))

function HeroVideo ({
  state, setState
}: {
  state: {
    open: boolean;
  };
  setState: any;
}) {
  const classes = useStyles();
  const video = useStaticQuery(graphql`
    query HeroVideoQuery {
      sanityHeroVideo {
        title
        video {
          url
        }
        mainImage {
          asset {
            fluid (maxWidth: 400) {
              srcSetWebp
              srcSet
              srcWebp
              aspectRatio
              base64
              sizes
              src
            }
          }
        }
      }
    }
  `)?.sanityHeroVideo;
  const id = getYouTubeId(video.video.url);
  const closeVideo = () => setState({open: false});
  return (
    <Dialog open={state.open} PaperProps={{
      square: true,
      className: classes.dialogPaper
    }} onClose={closeVideo}>
        <div className={classes.closeContainer}>
          <IconButton onClick={closeVideo}><Close /></IconButton>
        </div>
      <div className={classes.videoContainer}>
        {/*<Image fluid={video.mainImage.asset.fluid} />*/}
        <YouTube className={classes.video} opts={{
          height: 250, width: 400, playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
          },
        }} videoId={id} />
      </div>
    </Dialog>
  )
}

export default HeroVideo;
