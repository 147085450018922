import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  Typography, Paper, useMediaQuery
} from '@material-ui/core';
import {
  Star
} from '@material-ui/icons';
import {
  makeStyles, ThemeProvider
} from '@material-ui/styles';
import Slider from 'react-slick';
import { assoc, range } from 'ramda';

import PortableText from '../../content/portableText';

import lightTheme from '../../../themes/lightTheme';
import darkTheme from '../../../themes/baseTheme';

import "./testimonials.css";
import Marquee from "react-fast-marquee";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    background: theme.palette.background.default,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    color: 'white',
    fontSize: 48,
    fontFamily: 'Jost',
    fontWeight: 700,
    margin: 'auto',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  testimonial: {
    flexGrow: 1,
    margin: theme.spacing(),
    padding: '35px 22px',
    minHeight: 353,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: lightTheme.palette.background.paper,
    transition: 'background 1000s',
    fontStyle: 'italic',
    borderRadius: '16px',
    position: 'relative',
    zIndex: 1,
    height: '100%'
  },
  active: {
    // maxWidth: 600,
    // margin: 'auto',
    marginBottom: theme.spacing(2),
    background: 'rgb(237, 28, 36)',
    '&::after': {
      content: '""',
      width: '100%',
      height: '100%',
      zIndex: -1,
      position: 'absolute',
      top: -4, left: 0,
      background: 'white',
      borderRadius: '16px'
    }
  },
  inactive: {
    background: `rgba(250,250,250,1)`,
  },
  ratingContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    fontWeight: 'bold'
  },
  sliderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  slider: {
    // maxWidth: 900,
    [theme.breakpoints.down('sm')]: {
      maxWidth: `100vw`,
    }
  }
}))

const Testimonials = ({ state, feature1, update }) => {
  const classes = useStyles();
  const screenIsSmall = useMediaQuery(lightTheme.breakpoints.down('sm'));
  const testimonials = useStaticQuery(graphql`
    query TestimonialQuery {
      allSanityTestimonial {
        nodes {
          _id
          _rawBody(resolveReferences: {maxDepth: 10})
          publishedAt
          rating
          clientName
          title
        }
      }
    }
  `)?.allSanityTestimonial?.nodes || [];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // fade: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    // autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: screenIsSmall ? false : true,
    className: "center",
    centerPadding: "60px",
    focusOnSelect: true,
    // variableWidth: true,
    beforeChange: (current, next) => setActiveSlide(assoc('activeSlideIdx', next, activeSlide))
  };
  const CustomSlide = (args) => {
    const { index, testimonial, ...props } = args;
    const review = testimonial?._rawBody;
    return <div {...props}>
      <ThemeProvider theme={lightTheme}>
        <Paper square className={`${classes.testimonial} ${index === activeSlide.activeSlideIdx ? classes.active : classes.inactive}`}>
          <svg width="39" height="28" viewBox="0 0 39 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4533 27.7992H0.213252L11.7333 0.559216H19.5333L12.4533 27.7992ZM31.8933 27.7992H19.6533L31.1733 0.559216H38.9733L31.8933 27.7992Z" fill="#ED1C25" />
          </svg>

          <div>
            <Typography variant='h5' align='center' className={classes.title}>{testimonial.title}</Typography>
            {review && <PortableText blocks={review} />}
            <div className={classes.ratingContainer}>
              {range(0, testimonial.rating).map((rating) => <Star fontSize='large' key={rating} style={{ fill: lightTheme.palette.primary.main }} />)}
            </div>
            <Typography align='center'>- {testimonial.clientName}</Typography>
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  };
  const [activeSlide, setActiveSlide] = React.useState({
    activeSlideIdx: 0
  });
  return (
    <div className={classes.root}>
      <Typography align='center' className={classes.heading} variant='h3'>What Our Clients Are Saying</Typography>      <div className={classes.sliderContainer}>
        <div className={classes.slider}>
          {/* <Slider {...settings}> */}
          <Marquee speed={15} loop={0} style={{overflow: 'visible', maxWidth:900}}>
            {testimonials.map((testimonial, index) => {
              const review = testimonial?._rawBody;
              // <CustomSlide style={{display: 'flex'}} index={idx} testimonial={testimonial} />
              return (
                <ThemeProvider theme={lightTheme}>
                  <Paper style={{ maxWidth: 400 }} square className={`${classes.testimonial} ${index === activeSlide.activeSlideIdx ? classes.active : classes.inactive}`}>
                    <svg width="39" height="28" viewBox="0 0 39 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.4533 27.7992H0.213252L11.7333 0.559216H19.5333L12.4533 27.7992ZM31.8933 27.7992H19.6533L31.1733 0.559216H38.9733L31.8933 27.7992Z" fill="#ED1C25" />
                    </svg>
                    <div>
                      <Typography variant='h5' align='center' className={classes.title}>{testimonial.title}</Typography>
                      {review && <PortableText blocks={review} />}
                      <div className={classes.ratingContainer}>
                        {range(0, testimonial.rating).map((rating) => <Star fontSize='large' key={rating} style={{ fill: lightTheme.palette.primary.main }} />)}
                      </div>
                      <Typography align='center'>- {testimonial.clientName}</Typography>
                    </div>
                  </Paper>
                </ThemeProvider>

              )
            }
            )}
          </Marquee>
          {/* </Slider> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
