import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import {
  makeStyles, ThemeProvider
} from '@material-ui/styles';
import {
  keys
} from 'ramda';
import {
  Typography, Paper, Zoom, Button,
} from '@material-ui/core';
import {
  ArrowForward
} from '@material-ui/icons';
// import theme from '../../../themes/baseTheme';
import theme from '../../../themes/lightTheme';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    // justifyContent: 'space-around',
    gap: `${theme.spacing(3)}px`,
    justifyContent: 'center',
    maxWidth: 1180
  },
  cardBehind: {
    width: 350,
    maxWidth: '100vw',
    // minHeight: 250,
    position: 'relative',
    
    zIndex: 1,
    "&::before": {
      content: '""',
      background: 'red',
      position: 'absolute',
      top: 8,
      right: 8,
      zIndex: -10,
      borderRadius: '0 16px 0 0',
      width: '100px', // Adjust as needed
      height: '100px', // Adjust as needed
      clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
    }
    // WebkitClipPath: 'polygon(0 0, 90% 0, 100% 15%, 100% 100%, 0 100%, 0% 50%);'
  },
  card: {
    // minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(4),
    borderRadius: '25px 80px 25px 25px',
    background: 'white',
    zIndex: 10,
    gap: '16px',
    // WebkitClipPath: 'polygon(0 0, 90% 0, 100% 15%, 100% 100%, 0 100%, 0% 50%);'
  },
  heading: {
    color: '#242E49',
    fontSize: 48,
    fontFamily: 'Jost',
    fontWeight: 700
  },
  title: {
    fontWeight: 700,
    fontFamily: 'Jost',
    fontSize: '20px'
  },
  content: {
    // position: 'absolute',
    // top: 0,
    // width: '100%',
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
    // justifyContent: 'center'
  },
  contentB: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center'
  },
  subtitle: {
    fontWeight: 700,
    fontFamily: 'Jost',
    color: theme.palette.text.hint
  },
  subtitleB: {
    fontWeight: 900,
    color: theme.palette.text.secondary
  },
  buttonWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  cardBackground: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    filter: 'brightness(40%)',
    WebkitFilter: 'brightness(40%)',
  },
  cardBackgroundB: {
    // position: 'absolute',
    // top: 0,
    // width: '100%',
    // height: '100%',
    height: 200,
    zIndex: 0,
    // filter: 'brightness(30%)',
    // WebkitFilter: 'brightness(30%)',
  },
  ctaContainer: {
    // position: 'absolute',
    // bottom: theme.spacing(),
    // width: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  ctaContainerB: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: theme.spacing()
  },
  description: {
    fontColor: theme.palette.text.secondary,
    fontFamily: 'Inter',
    fontSize: '16px'
  }

})
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      sealcoat: file(relativePath: { eq: "services/drone-parking-lot.jpg" }) {
        childImageSharp {
          fixed(height:300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      paving: file(relativePath: { eq: "services/paving.jpg" }) {
        childImageSharp {
          fixed(height:300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      asphalt_repair: file(relativePath: { eq: "services/asphalt-repair.jpg" }) {
        childImageSharp {
          fixed(height:300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      concrete: file(relativePath: { eq: "services/concrete.jpg" }) {
        childImageSharp {
          fixed(height:300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      recycling: file(relativePath: { eq: "services/recycling.jpg" }) {
        childImageSharp {
          fixed(height:300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mulch: file(relativePath: { eq: "services/mulch.jpg" }) {
        childImageSharp {
          fixed(height:300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const services = {
    Paving: {
      subtitle: "Largest Independent",
      desc: "C & R provides value and reliability for your residential and commercial paving projects.",
      image: 'paving',
      linkTo: '/commercial-paving-service/'
    },
    "Sealcoat, Crackfill, & Striping": {
      subtitle: "Top Rated",
      desc: "C & R provides durability and protection for all your pavement needs.",
      image: 'sealcoat',
      linkTo: '/commercial-sealcoating/'
    },
    "Asphalt Repair": {
      subtitle: 'Long Lasting',
      desc: "C & R can maximize the performance of asphalt pavement by developing a plan of preventative maintenance and repair.",
      image: 'asphalt_repair',
      linkTo: '/commercial-asphalt-repair/'
    },
    Concrete: {
      subtitle: 'Expert',
      desc: 'C & R delivers customized residential and commercial concrete repairs, replacement, or new construction needs.',
      image: 'concrete',
      linkTo: '/concrete/'
    },
    "Mulch, Topsoil, & Tipping": {
      subtitle: 'Highest Quality',
      desc: 'C & R delivers customized residential and commercial concrete repairs, replacement, or new construction needs.',
      image: 'mulch',
      linkTo: '/mulch/',
      cta: 'Visit C & R Mulch'
    },
    "Crushed Stone & Millings": {
      subtitle: '100% Recycled',
      desc: 'C & R Asphalt has been the leading asphalt recycling facility in Central Kentucky for over a decade.',
      image: 'recycling',
      linkTo: '/recycling-products/'
    }
  }
  const [version, setVersion] = React.useState('A');
  return <section id='our-services' style={{paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
  <ThemeProvider theme={version === 'A' ? theme : theme}>
    <Typography align='center' className={classes.heading} variant='h3'>Our Services</Typography>
    {/*<div className={classes.root}>
      <Button style={{color: 'black', border: `2px solid ${version === 'A' ? 'black' : 'transparent'}`}} onClick={() => setVersion('A')}>Version A</Button>
      <Button style={{color: 'black', border: `2px solid ${version === 'B' ? 'black' : 'transparent'}`}} onClick={() => setVersion('B')}>Version B</Button>
      <Button style={{color: 'black', border: `2px solid ${version === 'C' ? 'black' : 'transparent'}`}} onClick={() => setVersion('C')}>Version C</Button>
    </div>*/}
      <div className={classes.root}>
        {keys(services).map((service, idx) =>

            <div className={classes.cardBehind}>
              <div className={classes.card}>
              {services[service].image && <div style={{borderRadius: '16px', overflow: 'hidden', height: '200px'}}>
                  <Img
                  alt={services[service].image} imgStyle={{marginBottom: 0, objectFit: 'cover', height: '200px'}} style={{height: 200}} fixed={data?.[services[service].image]?.childImageSharp?.fixed}
                  // className={version === 'A' ? classes.cardBackground : classes.cardBackgroundB}
                  />
                </div>}
              <div className={version === 'A' ? classes.content : classes.contentB}>
                {/* <Typography className={classes.subtitle} variant='overline'>{services[service].subtitle}</Typography> */}
                <Typography align='center' className={classes.title} variant='h5'>{service}</Typography>
                <Typography color="textSecondary" className={classes.description}>{services[service].desc}</Typography>
              </div>
              <div className={version === 'A' ? classes.ctaContainer : classes.ctaContainerB}>
                <Link to={services[service].linkTo} style={{textDecoration: 'none'}}>
                  <Button variant='outlined'>{services[service].cta || `Learn More`} <ArrowForward /></Button>
                </Link>
              </div>
            </div>
            </div>
        )}
      </div>
    </ThemeProvider>
  </section>

}

export default Image
