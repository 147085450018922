import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Img from "gatsby-image";
import {
  makeStyles
} from '@material-ui/styles';
import {
  Typography, Button, useMediaQuery
} from '@material-ui/core';
// import {
//   ArrowForward as ArrowRight
// } from '@material-ui/icons';
import {
  PlayArrowRounded as Play, Phone
} from '@material-ui/icons';

import ProjectForm from '../projectStart/projectStartForm';
import WatchVideo from './hero/video';
import {
  update,
} from '../../state/project/ducks';
import theme from '../../themes/baseTheme';

const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
      <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z" clip-rule="evenodd" />
    </svg>
  )
};

const PlayIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" >
    <g clip-path="url(#clip0_1_30)">
      <path d="M15 30C10.9934 30 7.22649 28.4397 4.39339 25.6066C1.56029 22.7735 0 19.0066 0 15C0 10.9934 1.56029 7.22649 4.39339 4.39339C7.22649 1.56029 10.9934 0 15 0C19.0066 0 22.7735 1.56029 25.6066 4.39339C28.4397 7.22649 30 10.9934 30 15C30 17.9608 29.1213 20.8404 27.4585 23.3272C27.0989 23.8653 26.3711 24.0097 25.833 23.6501C25.2951 23.2903 25.1505 22.5625 25.5103 22.0246C26.9142 19.9244 27.6562 17.4955 27.6562 15C27.6562 8.02139 21.9786 2.34375 15 2.34375C8.02139 2.34375 2.34375 8.02139 2.34375 15C2.34375 21.9786 8.02139 27.6562 15 27.6562C17.3147 27.6562 19.5788 27.0259 21.5472 25.8337C22.1006 25.4984 22.8211 25.6753 23.1567 26.2289C23.492 26.7824 23.315 27.5031 22.7614 27.8382C20.4268 29.2525 17.7429 30 15 30ZM13.726 20.525L19.568 17.1405C20.3398 16.6933 20.8008 15.8931 20.8008 15C20.8008 14.1069 20.3398 13.3067 19.5678 12.8595L13.726 9.47502C12.9536 9.02756 12.0305 9.02641 11.2571 9.47227C10.4821 9.91882 10.0195 10.7201 10.0195 11.6155V18.3845C10.0195 19.2799 10.4821 20.0812 11.2571 20.5277C11.6432 20.7502 12.0662 20.8612 12.4892 20.8612C12.914 20.8614 13.339 20.7493 13.726 20.525ZM12.5512 11.5029L18.3929 14.8874C18.4106 14.8977 18.457 14.9245 18.457 15C18.457 15.0755 18.4106 15.1023 18.3929 15.1126L12.5512 18.4971C12.5327 18.5076 12.4894 18.5328 12.4276 18.4971C12.3633 18.46 12.3633 18.4071 12.3633 18.3845V11.6155C12.3633 11.5929 12.3633 11.54 12.4276 11.5029C12.4514 11.4892 12.4725 11.4846 12.4903 11.4846C12.5189 11.4844 12.5397 11.4965 12.5512 11.5029Z" fill="inherit" />
    </g>
    <defs>
      <clipPath id="clip0_1_30">
        <rect width="30" height="30" fill="inherit" />
      </clipPath>
    </defs>
  </svg>
}


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  heroContent: {
    position: 'absolute',
    top: 190,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'start',
      top: 80
    },
  },
  heroText: {
    color: 'white',
    fontWeight: 700,
    fontSize: 68,
    fontFamily: 'Jost',
    [theme.breakpoints.down('sm')]: {
      fontSize: 42
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 34
    },
    width: '100%'
  },
  heroSubTextWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  heroSubText: {
    color: 'white',
    maxWidth: 550,
    fontFamily: 'Inter',
    textShadow: '1px 1px 2px black',
    fontSize: 20,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  heroTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 1180,
    padding: theme.spacing(2),
    paddingBottom: '90px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '140px',
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
  btn: {
    fontWeight: 600,
    minWidth: 180,
    borderRadius: '12px',
    padding: '14px 30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  iconWrapper: {
    height: 30,
    width: 30,
    // borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playIconWrapper: {
    height: 30,
    width: 30,
  },
  ctaContainer: {
    display: 'flex',
    paddingTop: theme.spacing(),
    flexWrap: 'wrap',
    gap: `${theme.spacing(4)}px`,
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      gap: `${theme.spacing(2)}px`,
    },
  },
  bottomBanner: {
    // position: 'absolute',
    // top: 0,
    marginTop: -180,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    zIndex: 0,
  },
  bannerImage: {
    height: '100vh',
    minHeight: 750
  },
  credLogos: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'center',
    alignItems: 'around',
    width: '100%',
    // maxWidth: 1400,
    marginBottom: theme.spacing(),
    flexWrap: 'wrap',
    background: 'rgba(255,255,255,0.55)',
    maxWidth: '1180px',
    borderRadius: 25,
    boxShadow: "45.82px 43px 183.281px 0px rgba(85, 93, 175, 0.07)",
    border: '2px solid white',
    backdropFilter: 'blur(22.5px)',
    padding: 24,
    paddingLeft: 48,
    paddingRight: 48,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  credLogo: {
    padding: theme.spacing(),
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      // height: 20
    },
    // background: 'white',
    borderRadius: '8px'
  },
  callUsButton: {
    fontSize: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  }
}))
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const HeroComponent = ({
  update
}: {
  update: (keypath: string[], value: any) => void;
}) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      placeholderImageLarge: file(relativePath: { eq: "home/aa_home.jpeg" }) {
        childImageSharp {
          fluid(cropFocus: SOUTHWEST, maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImageSmall: file(relativePath: { eq: "home/aa_home.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bbbLogoLg: file(relativePath: { eq: "layout/bbb.png"}) {
        childImageSharp {
          fixed(height: 50, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bbbLogoSm: file(relativePath: { eq: "layout/bbb.png"}) {
        childImageSharp {
          fixed(height: 40, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoSixteenLg: file(relativePath: { eq: "layout/2016.jpg"}) {
        childImageSharp {
          fixed(height: 50, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoSixteenSm: file(relativePath: { eq: "layout/2016.jpg"}) {
        childImageSharp {
          fixed(height: 40, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoSeventeenLg: file(relativePath: { eq: "layout/2017.jpg"}) {
        childImageSharp {
          fixed(height: 50, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoSeventeenSm: file(relativePath: { eq: "layout/2017.jpg"}) {
        childImageSharp {
          fixed(height: 40, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoEighteenLg: file(relativePath: { eq: "layout/2018.jpg"}) {
        childImageSharp {
          fixed(height: 50, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoEighteenSm: file(relativePath: { eq: "layout/2018.jpg"}) {
        childImageSharp {
          fixed(height: 40, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoNineteenLg: file(relativePath: { eq: "layout/2019.jpg"}) {
        childImageSharp {
          fixed(height: 50, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoNineteenSm: file(relativePath: { eq: "layout/2019.jpg"}) {
        childImageSharp {
          fixed(height: 40, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoTwentyLg: file(relativePath: { eq: "layout/2020.jpg"}) {
        childImageSharp {
          fixed(height: 50, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoTwentySm: file(relativePath: { eq: "layout/2020.jpg"}) {
        childImageSharp {
          fixed(height: 40, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoTwentyOneLg: file(relativePath: { eq: "layout/2021.jpg"}) {
        childImageSharp {
          fixed(height: 50, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoTwentyOneSm: file(relativePath: { eq: "layout/2021.jpg"}) {
        childImageSharp {
          fixed(height: 40, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoTwentyTwoLg: file(relativePath: { eq: "layout/2022.jpg"}) {
        childImageSharp {
          fixed(height: 50, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoTwentyTwoSm: file(relativePath: { eq: "layout/2022.jpg"}) {
        childImageSharp {
          fixed(height: 40, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      topLogoTwentyThreeLg: file(relativePath: { eq: "layout/2023.jpg"}) {
        childImageSharp {
          fixed(height: 50, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      topLogoTwentyThreeSm: file(relativePath: { eq: "layout/2023.jpg"}) {
        childImageSharp {
          fixed(height: 40, grayscale: false) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const credLogosLg = [
    'bbbLogoLg',
    'topLogoSixteenLg',
    'topLogoSeventeenLg',
    'topLogoEighteenLg',
    'topLogoNineteenLg',
    'topLogoTwentyLg',
    'topLogoTwentyOneLg',
    'topLogoTwentyTwoLg',
    'topLogoTwentyThreeLg',
    // 'topLogoSeventeenLg',
    // 'topLogoSeventeenLg',
  ];
  const credLogosSm = [
    'topLogoSixteenSm',
    'topLogoSeventeenSm',
    'topLogoEighteenSm',
    'topLogoNineteenSm',
    'topLogoTwentySm',
    'topLogoTwentyOneSm',
    'topLogoTwentyTwoSm',
    'topLogoTwentyThreeSm',
    'bbbLogoSm',
  ];
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const credLogos = screenIsSmall ? credLogosSm : credLogosLg;
  const [video, setVideo] = React.useState({ open: false });
  const openProject = () => update(['form', 'currentQuestion'], 'projectType');
  console.log({screenIsSmall});
  return <div className={classes.root}>
    <Img imgStyle={{
      marginBottom: 0,
      height: 'calc(100vh - 90px)',
      minHeight: '725px',
      filter: 'brightness(70%)',
      WebKitFilter: 'brightness(70%)',
      borderRadius: screenIsSmall ? 'none' : '0 0 90px 90px',
    }} className={classes.bannerImage} fluid={data.placeholderImageLarge.childImageSharp.fluid} />
    <div className={classes.heroContent}>
      <div className={classes.heroTextWrapper}>
        <Typography className={classes.heroText} variant='h1'>Central KY's Top <br /> Paving Contractor</Typography>
        <div className={classes.heroSubTextWrapper}>
          <Typography className={classes.heroSubText} variant='h2'>{`We've been serving the Bluegrass for ${new Date().getFullYear() - 1993} years. Start your next project with us today. `}</Typography>
        </div>
        <div className={classes.ctaContainer}>
          <Button
            variant='contained'
            className={classes.btn}
            onClick={openProject}
            color='primary'>Get A Free Quote <span style={{ marginLeft: '0.5rem' }} className={classes.iconWrapper}><ArrowRight /></span></Button>
          <Button
            variant='contained'
            className={classes.btn}
            onClick={() => setVideo({ open: true })}
            color='secondary'>Watch the video <span style={{ marginLeft: '0.5rem' }} className={classes.playIconWrapper}><PlayIcon /></span></Button>

        </div>
        {/* <div className={classes.ctaContainer} style={{marginTop: 18}}>
          <a style={{textDecoration: 'none'}} href="tel:+18592550077">
            <Button style={{fontWeight: 800}} className={classes.callUsButton} variant='outlined'>
              {`Call us at (859) 255-0077`} <Phone style={{marginLeft: theme.spacing()}} />
            </Button>
          </a>
        </div> */}
      </div>
    </div>
    <div className={classes.bottomBanner}>
      <div className={classes.credLogos}>
        {credLogos.map((l, idx) => <div className={classes.credLogo} key={idx}>
            <Img imgStyle={{margin: 0}} fadeIn={false} fixed={data[l].childImageSharp.fixed} />
          </div>
        )}
      </div>
    </div>

    {/*<div className={classes.playButton}>
      <Play className={classes.play} />
    </div>*/}
    <ProjectForm />
    <WatchVideo state={video} setState={setVideo} />
  </div>
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({
      update
    }, dispatch)
  };
};

const Hero = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeroComponent);

export default Hero;
