import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Divider} from '@material-ui/core';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { grey } from '@material-ui/core/colors';

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import Hero from '../components/home/hero';
import Services from '../components/home/services/cards';
import Testimonials from '../components/home/testimonials/testimonials';
import CredBanner from '../components/home/credBanner';

const IndexPage = ({state, feature1, update}) => {
  const homePageSeoTitle = "C&R Asphalt Animals |  Lexington's Top Rated Paving Company | Serving Lexington, Louisville, Georgetown, Versailles, Frankfort, Winchester";

  const data = useStaticQuery(graphql`
  query CredBannerQueryLexington {
    trustedLocally: sanityCredBanner(title: {eq: "Trusted Locally"}) {
      title
      logos {
        asset {
          _id
        }
      }
    }
    establishedNationally: sanityCredBanner(title: {eq: "Established Nationally"}) {
      title
      logos {
        asset {
          _id
        }
      }
    }
  }
  `);

  return (
    <Layout>
      <SEO title={homePageSeoTitle} />
      <Hero />
      <CredBanner data={data.trustedLocally} />
      <CredBanner offset data={data.establishedNationally} />
      {/*<div style={{width: '100%'}}>*/}
      {/*</div>*/}
      <Services />
      <Testimonials />
    </Layout>
  );
};

const mapStateToProps = state => {
  // const feature1 = state.feature1;
  return {  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
    }, dispatch),
  }
};

const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage);

export default Index;
